import { useEffect, useState } from "react";

export const currencyFormatter = Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

export function currencyWithNoFormat(number: any) {
    let format = currencyFormatter.format(number);
    return format.slice(0, format.length - 2);
}

export function checkUndefinedNumber(number: any) {
    if (typeof number === "number" || (typeof (number * 1) === "number")) {
        return number * 1;
    }
    return 0;
}

export function covertObjectToStringParam(o: any) {
    return Object.entries(o)
        .map(([key, val]) => {
            if (val === undefined) {
                return
            }
            return `${key}=${val}`
        })
        .join("&");
}

export function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])

    return debouncedValue
}

