import { IProductDetail, IProductDetailPayment, gaMeasurementId } from "../@types/IProduct/IProductDetail";
import { IProductAccessory, IProductAccessoryPayment } from "../@types/IProduct/IProductAccessory";
import { IGeneralSetting, IHolidays } from "../@types/IContext/IAppContext";
import { checkProductIsFullSet } from "../components/Cart/util";
import moment from "moment";
import toast from "react-hot-toast";
import { ICartGtag, ICartItemGtag } from "../components/gtag/interface";
import { event } from "nextjs-google-analytics";
import { log } from "console";
import { diffInDay } from "../components/ProductList/utils";
import { checkUndefinedNumber } from "./format";
import { loadavg } from "os";


const TabConst = {
    rent: "1",
    buy: "2"
}

const TabList = [
    { label: 'Đơn thuê', value: TabConst.rent },
    { label: 'Đơn mua', value: TabConst.buy },
]

const TransactionType = {
    storageMoney: 1,
    bail: 2,
}

const TransactionTypeList = [
    { label: 'Cọc giữ đồ', value: TransactionType.storageMoney },
    { label: 'Thế chân', value: TransactionType.bail },
]

const DeliveryType = {
    cod: 0,
    pickupDirectly: 1,
}

const DeliveryTypeList = [
    { label: 'Giao hàng tận nơi', value: DeliveryType.cod },
    { label: ' Lấy hàng trực tiếp', value: DeliveryType.pickupDirectly },
]

const LabelCustomer: any = {
    name: 'Họ tên',
    email: 'Email',
    phone: 'Số điện thoại',
    address: 'Địa chỉ',
    province_id: 'Tỉnh/Thành phố',
    district_id: 'Quận/Huyện',
    ward_id: 'Phường/Xã'
}

export const PaymentConst = {
    TabList,
    TabConst,

    TransactionType,
    TransactionTypeList,

    DeliveryType,
    DeliveryTypeList,

    LabelCustomer,
    Color: {
        primary: '#274FF5',
        success: '#52C41A',
    }

}

export function checkHolidayAndDayOff(workingDay: Number[], holidays: IHolidays[], product: IProductDetailPayment) {
    let isValid = 0;
    let all_day = [0, 1, 2, 3, 4, 5, 6];
    let return_date = moment(product?.return_date).format("YYYY-MM-DD");
    let start_date = moment(product?.take_date).format("YYYY-MM-DD");
    let not_working_day = all_day?.filter((e: any) => workingDay.indexOf(e) == - 1);

    if (not_working_day.includes(new Date(return_date).getDay())) {
        isValid = 1;
        return isValid;
    }
    if (not_working_day.includes(new Date(start_date).getDay())) {
        isValid = 4;
        return isValid;
    }

    for (let index = 0; index < holidays.length; index++) {
        const el = holidays[index];

        if ((new Date(return_date) >= new Date(el.start_date) && new Date(return_date) <= new Date(el.end_date)) || new Date(return_date) === new Date(el.start_date) || new Date(return_date) === new Date(el.end_date)) {
            isValid = 2;
            break;
        }
        if ((new Date(start_date) >= new Date(el.start_date) && new Date(start_date) <= new Date(el.end_date)) || new Date(start_date) === new Date(el.start_date) || new Date(start_date) === new Date(el.end_date)) {
            isValid = 3;
            break;
        }
    }

    return isValid
}



export const addToCart = async (
    workingDay: Number[],
    holidays: IHolidays[],
    sellCarts: IProductDetailPayment[],
    rentCarts: IProductDetailPayment[],
    setCarts: Function,
    products: IProductDetailPayment[],
    product: IProductDetailPayment | any,
    is_purchase: boolean | null | any,
    qty: number,
    isAttached?: boolean
) => {
    //console.log("cart 1", product);
    //console.log("cart 1.1", rentCarts);
    //console.log('product', product);
    let status = checkHolidayAndDayOff(workingDay, holidays, product);
    if (status !== 0 && !is_purchase) {
        if (!product?.take_date || !product?.return_date) {
            //toast.error(`Thời gian thuê của sản phẩm không được bỏ trống`)
        } else {
            if (status === 1) {
                toast.error("Ngày trả trùng với ngày nghỉ của cửa hàng. Vui lòng chọn ngày khác");
            } else if (status === 2) {
                toast.error("Ngày thuê trùng với ngày nghỉ của cửa hàng. Vui lòng chọn ngày khác");
            } else if (status === 4) {
                toast.error("Ngày thuê trùng với ngày nghỉ của cửa hàng. Vui lòng chọn ngày khác");
            } else {
                toast.error("Ngày thuê trùng với ngày nghỉ của cửa hàng. Vui lòng chọn ngày khác");
            }

        }
        return
    }

    let isSuccess = true;
    let isAdd = false;
    let cloneProduct = { ...product };
    // let cloneProduct1 = { ...product }
    //let rentProduct = rentCarts?.find((item: IProductDetailPayment) => item.id === cloneProduct.id)?.qty ?? 0;
    //let sellProduct = sellCarts?.find((item: IProductDetailPayment) => item.id === cloneProduct.id)?.qty ?? 0;

    cloneProduct.accessories = product.accessories;

    //tạm ẩn
    if (
        //rentProduct + sellProduct + qty > cloneProduct.amount
        false
        &&
        !isAttached) {

        isSuccess = false;
    } else {



        if (cloneProduct?.accessories?.length > 0) {
            let accessories = [...cloneProduct.accessories];
            let resultAccessories: IProductAccessory[] = [];
            accessories.forEach((acc: IProductDetail | any) => {
                if (acc?.count > 0) {
                    resultAccessories.push({
                        ...acc,
                        qty: acc.count,
                        take_date: product.take_date,
                        return_date: product.return_date
                    });

                    isAdd = true;
                }
            })

            cloneProduct.accessories = resultAccessories;
            cloneProduct.qty = qty;

        } else if (qty > 0) {
            cloneProduct.qty = qty;
            isAdd = true;
        }
        if (!isAdd) {
            //console.log("tst", isAdd);

            return;
        }
        const indexOld: number = products.findIndex((p: IProductDetailPayment) => {
            if (
                (p.is_purchase && p.id === product.id)
                || (!p.is_purchase && p.id === product.id && moment(product?.take_date).format("DD/MM/YYYY") === moment(product.take_date).format("DD/MM/YYYY") && moment(p.return_date).format("DD/MM/YYYY") === moment(product.return_date).format("DD/MM/YYYY"))
            ) {
                return p;
            }
        });

        let cloneProducts = [...products];
        if (indexOld !== -1 && products[indexOld].is_purchase === is_purchase && cloneProduct.accessories?.map((e: any) => e.code) === products[indexOld]?.accessories?.map((e: any) => e.code)) {
            if (
                cloneProduct.accessories?.map((e: any) => e.code) === products[indexOld]?.accessories?.map((e: any) => e.code)
            ) {
                cloneProduct.qty += products[indexOld]?.qty ?? 0;

                cloneProduct.accessories = products[indexOld]?.accessories?.map((e: any, index: any) => {
                    if (cloneProduct.accessories[index].code === e.code) {
                        //console.log('In 23232312', e.name);

                        e.qty += cloneProduct.accessories[index]?.qty ?? 0
                    }

                    return e;
                })
                cloneProducts[indexOld] = {
                    ...cloneProduct,
                    is_purchase: !!is_purchase,
                }
            } else {
                //console.log("products 1111", cloneProducts);
                cloneProduct.id = Math.random();
                cloneProduct.is_purchase = !!is_purchase
                cloneProducts = [
                    ...cloneProducts,
                    cloneProduct
                ]
            }
            //console.log("products 22233", cloneProducts);
            //console.log("cloneProduct", cloneProduct);




            if (isSuccess === true) {
                if (cloneProduct?.is_product === false) {
                    cloneProducts?.map((item: IProductDetailPayment) => {
                        item.accessories = item.temp_accessories?.map((item: any) => {
                            item.qty = item.count,
                                item.product_amount = item.product_amount
                            return item
                        })
                        return item;
                    })
                }
                //console.log("products set", cloneProducts);
                // await setCarts([...cloneProducts]?.map((item: any) => {
                //     item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length;
                //     return item;
                // }));
            }

        } else {
            //console.log("cart 2");
            if (isSuccess === true) {

                if (is_purchase) {
                    let findItem = sellCarts?.find((item: any) => item?.id === cloneProduct?.id);
                    if (findItem) {
                        let cloneSellCarts = sellCarts?.map((item: any) => {
                            if (item.id === cloneProduct?.id) {
                                item.qty += cloneProduct.qty;
                                item.accessories = item.accessories?.map((e: any) => {
                                    let findQty = cloneProduct.accessories?.find((acc: any) => acc.id === e.id)?.qty ?? null;
                                    if (findQty) {
                                        e.qty += findQty
                                    }
                                    return e;
                                })
                            }
                            item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length
                            return item;
                        });
                        //console.log('Cart 2.2');
                        if (cloneProduct.accessories?.map((e: any) => e.code) !== findItem?.accessories?.map((e: any) => e.code)) {
                            cloneProduct.id = Math.random();
                            cloneProduct.is_purchase = !!is_purchase;
                            cloneSellCarts = [
                                ...cloneSellCarts,
                                cloneProduct
                            ]
                        }
                        setCarts(cloneSellCarts?.map((item: any) => {
                            item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length;
                            return item;
                        }));
                    } else {
                        //console.log('Cart 2.3');
                        setCarts([
                            ...products,
                            {
                                ...cloneProduct,
                                is_purchase: !!is_purchase,
                            }
                        ]?.map((item: any) => {
                            item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length;
                            return item;
                        }))
                    }
                } else if (!is_purchase) {
                    //console.log("product", product);
                    //console.log('Cart 2.4');
                    let findItem = rentCarts?.find((item: any) => item?.id === cloneProduct?.id && moment(item?.take_date).format("DD/MM/YYYY") === moment(cloneProduct?.take_date).format("DD/MM/YYYY") && moment(item?.return_date).format("DD/MM/YYYY") === moment(cloneProduct?.return_date).format("DD/MM/YYYY"));
                    if (findItem) {
                        //console.log('Cart 2.5');
                        //console.log("cart 2.3.3");
                        if (product.is_bundling) {
                            //console.log('Cart 2.7');
                            setCarts([
                                ...products,
                                {
                                    ...cloneProduct,
                                    is_purchase: !!is_purchase,
                                }
                            ]?.map((item: any) => {
                                item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length;
                                return item;
                            }));
                            if (is_purchase) {
                                toast.success('Đặt mua thành công');
                            } else {
                                toast.success('Đặt thuê thành công');
                            }
                            return
                        }
                        let cloneRentCarts = rentCarts?.map((item: any) => {
                            if (item.id === cloneProduct?.id && cloneProduct.accessories?.map((e: any) => e.code) === item?.accessories?.map((e: any) => e.code)) {
                                item.qty += cloneProduct.qty;
                                item.accessories = item.accessories?.map((e: any) => {
                                    let findQty = cloneProduct.accessories?.find((acc: any) => acc.id === e.id)?.qty ?? null;
                                    if (findQty) {
                                        e.qty += findQty
                                    }
                                    return e;
                                })
                            }
                            item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length
                            return item;
                        });
                        if (cloneProduct.accessories?.map((e: any) => e.code) !== findItem?.accessories?.map((e: any) => e.code)) {
                            cloneProduct.id = Math.random();
                            cloneProduct.is_purchase = !!is_purchase;
                            cloneRentCarts = [
                                ...cloneRentCarts,
                                cloneProduct
                            ]
                        }
                        //console.log('Cart 2.8');
                        setCarts(cloneRentCarts?.map((item: any) => {
                            item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length;
                            return item;
                        }));
                    } else {
                        //console.log('Cart 2.6');
                        //console.log("cart 2.3.4");
                        setCarts([
                            ...products,
                            {
                                ...cloneProduct,
                                is_purchase: !!is_purchase,
                            }
                        ]?.map((item: any) => {
                            item.set_qty = item.accessories?.filter((e: any) => !e.bundling_id).length;
                            return item;
                        }))
                    }
                }

            }

        }
    }


    //console.log("in success", isSuccess);
    if (isSuccess) {
        event("add_to_cart", mapToGtag([
            ...products,
            {
                ...cloneProduct,
                is_purchase: !!is_purchase,
            }
        ], is_purchase));
        if (is_purchase) {
            //console.log("in success");

            toast.success('Đặt mua thành công');
        } else {
            toast.success('Đặt thuê thành công');
        }

    } else {
        toast.error("Quá số lượng tồn kho, không thể thêm vào giỏ")
    }
}


export function mapToGtag(array: IProductDetailPayment[], is_purchase: boolean) {
    let total_price = 0;

    let data: ICartGtag = {
        currency: "VND",
        value: 0,
        items: array?.map((item: IProductDetailPayment, index: number) => {
            total_price += is_purchase ? item.price ?? 0 : item.rental_price ?? 0
            let custom: ICartItemGtag = {
                item_id: item.id?.toString() ?? "",
                item_name: item.name,
                affiliation: "",
                coupon: "",
                discount: 0,
                index: index,
                item_category: "",
                item_brand: "",
                item_variant: "",
                location_id: "",
                price: is_purchase ? item.price : item.rental_price,
                quantity: item.qty ?? 0
            }
            return custom
        }) ?? []
    }
    return data;
}

const calculateSurchargeProductFullSet = (product: IProductDetailPayment, generalSetting: IGeneralSetting, qty: number, is_product: boolean, surchargeDay: number) => {

    let total_price = 0
    // let diffDay: number | any = 0;
    // let take_date = product.take_date ? moment(product.take_date) : null;
    // let return_date = product.return_date ? moment(product.return_date) : null;
    // diffDay = return_date?.diff(take_date, 'days');
    // diffDay = diffDay - (generalSetting.time_rent_package ?? 0);
    //console.log("diffDay", diffDay);

    if (surchargeDay > 0 && product.checked) {
        //console.log("surchargeDay 1", product, is_product);

        let price = product.is_purchase ? product.price : product.rental_price;
        //console.log(`price: ${price}, generalSetting.surcharge_time = ${generalSetting.surcharge_time}, diffDay: ${diffDay}, qty: ${qty}  `);

        total_price += ((price * generalSetting.surcharge_time) / 100) * surchargeDay * qty;
        //console.log('total_price', total_price);
    }
    //console.log("total_price", surchargeDay, is_product);
    //Edit: Delete is_product condition
    if (surchargeDay > 0 && product.accessories.length > 0) {
        //console.log("surchargeDay 2");
        product?.accessories?.forEach((acc: any, index: number) => {
            if (qty < acc.qty && !acc.bundling_id && acc.checked) {

                let price = product.is_purchase ? acc.price : acc.rental_price;
                total_price += ((price * generalSetting.surcharge_time) / 100) * surchargeDay * (acc.qty - qty)
            } else if (acc.bundling_id && acc.checked) { //sp bán kèm
                let price = product.is_purchase ? acc.price : acc.rental_price;
                total_price += ((price * generalSetting.surcharge_time) / 100) * surchargeDay * (acc.qty)
                //console.log("surchargeDay 2", total_price);
            }
        })
    }
    //console.log("total_price", total_price);

    return total_price;
}

export const calculateSurcharge = (workingDay: Number[], holidays: IHolidays[], products: IProductDetailPayment[], generalSetting: IGeneralSetting, isPurchase: boolean = false) => {
    let total = 0;

    for (let product of products.filter((pro: IProductDetailPayment) => pro.is_purchase == isPurchase)) {
        //console.log("sur 0");
        let surchargeDay = calSurchargeDay(workingDay, holidays, new Date(moment(product.take_date).format("YYYY-MM-DD")), new Date(moment(product.return_date).format("YYYY-MM-DD")), generalSetting.time_rent_package) ?? 0;
        //xử lý bán kèm
        let find_attached_accessories = [...product.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.bundling_id)];
        if (find_attached_accessories && find_attached_accessories.length > 0 && !product.checked) {
            find_attached_accessories?.map((acc: IProductAccessoryPayment, index: number) => {
                let price = product.is_purchase ? acc.price : acc.rental_price;
                total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * ((acc.qty ?? 0));
                return acc;
            })
            //return total;
        }
        if (!product.checked && !product.allowed_split) continue;

        let isFull = checkProductIsFullSet(product);
        let diffDay: number | any = 0;


        if ((isFull || product.is_product == false) && !product.is_bundling && product.checked) {
            //console.log("sur 1");
            total += calculateSurchargeProductFullSet(product, generalSetting, (product.qty ?? 0), product?.is_product ?? true, surchargeDay);
            // find_attached_accessories?.map((acc: IProductAccessoryPayment, index: number) => {
            //     let price = product.is_purchase ? acc.price : acc.rental_price;
            //     total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * ((acc.qty ?? 0));
            //     return acc;
            // })
            continue;
        }
        //console.log("sur 1.1", total);
        // if (product.is_bundling) {
        //     total += calculateSurchargeProductFullSet(product, generalSetting, (product.qty ?? 0))
        // }



        let cloneAccessories = [...product.accessories?.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0)];
        cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))
        //console.log("sur 2.0", cloneAccessories);
        if (cloneAccessories.length > 0) {
            //console.log("sur 2");

            let standardQty = 0;
            cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {
                //console.log("sur 2", acc);
                if (!acc.checked) return;
                if (index === 0) {
                    //console.log("sur 2.1", total);
                    standardQty = acc.qty ?? 0;
                    if (product.checked && !product.allowed_split) {
                        //console.log("sur 2.1.1", total);
                        total += calculateSurchargeProductFullSet(product, generalSetting, product?.is_bundling ? (product.qty ?? 0) : standardQty, product?.is_product ?? true, surchargeDay);
                    }

                    //console.log('total', total);

                    if (product?.is_bundling) {


                        if (surchargeDay > 0 && !product?.is_set) {
                            //console.log("sur 2.3", total);
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            //console.log(`price: ${price}, generalSetting.surcharge_time = ${generalSetting.surcharge_time}, diffDay: ${diffDay - generalSetting.time_rent_package}, qty: ${acc.qty}  `);
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * ((acc.qty ?? 0));
                            //console.log("total", total);

                        }
                    }
                    if (product.allowed_split && !product.checked) {
                        if (surchargeDay > 0) {
                            //console.log("sur 2.99", acc);
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * (acc.qty ?? 0);
                        }
                    }
                    //TH ĐB chỉ có 1 sp lẻ duy nhất
                    if (product.allowed_split && product.accessories.length === 1) {
                        if (surchargeDay > 0) {
                            //console.log("sur 3", acc);
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * (acc.qty ?? 0);
                        }
                    }
                } else {
                    if (product?.is_bundling) {
                        // let take_date = acc.take_date ? moment(product.take_date) : null;
                        // let return_date = acc.return_date ? moment(product.return_date) : null;

                        // diffDay = return_date?.diff(take_date, 'days');

                        if (surchargeDay > 0 && !product?.is_set) {
                            //console.log("sur 2.3", total);
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            //console.log(`price: ${price}, generalSetting.surcharge_time = ${generalSetting.surcharge_time}, diffDay: ${diffDay}, qty: ${acc.qty}  `);
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * ((acc.qty ?? 0));
                            //console.log("total", ((price * generalSetting.surcharge_time) / 100) * (diffDay - generalSetting.time_rent_package) * ((acc.qty ?? 0)));

                        } else if (acc.bundling_id) {
                            //console.log("sur 2.3.3", total);
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            //console.log(`price: ${price}, generalSetting.surcharge_time = ${generalSetting.surcharge_time}, diffDay: ${diffDay}, qty: ${acc.qty}  `);
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * ((acc.qty ?? 0));
                            //console.log("total", ((price * generalSetting.surcharge_time) / 100) * (diffDay - generalSetting.time_rent_package) * ((acc.qty ?? 0)));
                        }
                    } else if ((acc.qty ?? 0) - standardQty > 0 && product.checked) {
                        //console.log("sur 2.99.3", product);
                        // let take_date = acc.take_date ? moment(product.take_date) : null;
                        // let return_date = acc.return_date ? moment(product.return_date) : null;
                        // diffDay = return_date?.diff(take_date, 'days');


                        if (surchargeDay > 0) {
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * ((acc.qty ?? 0) - standardQty);
                        }
                    }
                    if (product.allowed_split && !product.checked) {
                        if (surchargeDay > 0) {
                            //console.log("sur 2.99.2", acc);
                            let price = product.is_purchase ? acc.price : acc.rental_price;
                            total += ((price * generalSetting.surcharge_time) / 100) * (surchargeDay) * (acc.qty ?? 0);
                        }
                    }
                }


            })
            continue;
        }

        // for (let accessory of product.accessories) {
        //     console.log("sur 3", accessory);

        //     if (!accessory.checked) continue;
        //     let take_date = accessory.take_date ? moment(product.take_date) : null;
        //     let return_date = accessory.return_date ? moment(product.return_date) : null;

        //     diffDay = return_date?.diff(take_date, 'days');

        //     diffDay = diffDay - generalSetting.time_rent_package
        //     if (diffDay > 0) {
        //         let price = product.is_purchase ? accessory.price : accessory.rental_price;
        //         total += ((price * generalSetting.surcharge_time) / 100) * diffDay * (accessory.qty ?? 0);
        //     }
        // }
    }
    //console.log('total', total);

    if (total < 0) {
        return 0
    }
    return Math.floor(total)
}

export const uuidV4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const prepareCalculateTransportFee = (products: IProductDetailPayment[]) => {
    let weight = 0;
    products.forEach((product: IProductDetailPayment) => {
        if (product.checked) {
            weight += (product?.weight ?? 0) * (product.qty ?? 0);
        } else {
            product.accessories?.forEach((acc: IProductAccessoryPayment) => {
                if (acc.checked) {
                    weight += (acc?.weight ?? 0) * (acc.qty ?? 0);
                }
            })
        }
    })

    return weight;
}

export function calSurchargeDay(workingDay: Number[], holidays: IHolidays[], takeDate: Date, returnDate: Date, time_rent_package: number) {
    if (takeDate === null || returnDate === null) {
        return
    }
    let all_day = [0, 1, 2, 3, 4, 5, 6];
    let take_date = moment(takeDate).format("YYYY-MM-DD");
    let return_date = moment(returnDate).format("YYYY-MM-DD");
    let inholiday = 0;
    let cloneTakeDay = moment(takeDate);
    let not_working_day = all_day?.filter((e: any) => workingDay.indexOf(e) == - 1);

    while (cloneTakeDay.isBefore(moment(returnDate).add(1, 'days'), 'day')) {
        // console.log("cloneTakeDay", cloneTakeDay);
        // console.log("Inn 123", not_working_day);
        // console.log("new Date(cloneTakeDay", new Date(cloneTakeDay.format("YYYY-MM-DD")).getDay());


        if (not_working_day.includes(new Date(cloneTakeDay.format("YYYY-MM-DD")).getDay()) && new Date(cloneTakeDay.format("YYYY-MM-DD")) <= new Date(return_date)) {
            //console.log("Inn 123");

            inholiday += 1;
        } else {
            for (let index = 0; index < holidays.length; index++) {
                let el = holidays[index];

                if ((new Date(moment(cloneTakeDay).format("YYYY-MM-DD")) >= new Date(el.start_date) && new Date(moment(cloneTakeDay).format("YYYY-MM-DD")) <= new Date(el.end_date)) || new Date(moment(cloneTakeDay).format("YYYY-MM-DD")) == new Date(el.start_date) || new Date(moment(cloneTakeDay).format("YYYY-MM-DD")) == new Date(el.end_date)) {
                    inholiday += 1;
                    break;
                }
            }

        }
        cloneTakeDay.add(1, 'days');
    }

    // for (let index = 0; index < holidays.length; index++) {
    //     let el = holidays[index];

    //     if ((new Date(return_date) >= new Date(el.start_date) && new Date(return_date) <= new Date(el.end_date)) || new Date(return_date) == new Date(el.start_date) || new Date(return_date) == new Date(el.end_date)) {
    //         inholiday += diffInDay(new Date(el.start_date), new Date(el.end_date));
    //         if (new Date(el.start_date) === new Date(el.end_date)) {
    //             inholiday += 1
    //         }
    //         break;
    //     }
    // }
    // console.log("inholiday", inholiday);

    return (diffInDay(new Date(moment(takeDate).format("YYYY-MM-DD")), new Date(moment(returnDate).format("YYYY-MM-DD"))) + 1 - checkUndefinedNumber(time_rent_package) - inholiday) ?? 0;
}
