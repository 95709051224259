
type TEnvironment = {
    apiKey?: string;
    baseUrl?: string;
    apiKeyEncrypt?: string
    baseImageUrl?: string;
    googleKey?: string
}
const environment: TEnvironment = {
    googleKey: process.env.GOOGLE_API_KEY,
    apiKey: process.env.API_KEY,
    baseUrl: process.env.REACT_API_BASE_URL,
    apiKeyEncrypt: process.env.API_KEY_ENCRYPT,
    baseImageUrl: `${process.env.REACT_API_BASE_URL}/rm/file`
}

export default environment