import React, { useContext, useEffect, useState } from "react";
import { calculateSurcharge, PaymentConst, prepareCalculateTransportFee } from "../utils/payment";
import {
    IAdditionalInformation,
    ICustomerAppContext,
    IPaymentContext
} from "../@types/IContext/IPaymentContext";
import browserStored from "../utils/browserStored";
import { STORAGE_KEYS } from "../utils/config";
import { IProductDetailPayment } from "../@types/IProduct/IProductDetail";
import AppContext from "./AppContext";
import { checkHasBundling, checkHasData, checkProductIsFullSet, prepareDataValidate, totalPayment, totalPaymentRentPrice } from "../components/Cart/util";
import { getFee } from "../pages/api/hello";

export const initCustomerPaymentContext = {
    name: '',
    phone: '',
    email: '',
    address: '',
    note: '',
    province_id: null,
    province_name: null,
    district_id: null,
    district_name: null,
    ward_id: null,
    ward_name: null,
    sex: 0,
}

export const initAdditionalInformation = {
    branch_id: null,
    delivery_type: PaymentConst.DeliveryType.cod,
    transaction_type: PaymentConst.TransactionType.storageMoney,
    branch_name: null,
}

export const initialValuePaymentContext: IPaymentContext | any = {
    sellCarts: [],
    setSellCarts: () => { },

    rentCarts: [],
    setRentCarts: () => { },

    currentStep: 1,
    setCurrentStep: () => { },

    rentAdditionalInformation: { ...initAdditionalInformation },
    setRentAdditionalInformation: () => { },

    sellAdditionalInformation: { ...initAdditionalInformation },
    setSellAdditionalInformation: () => { },

    tabActiveStep1: 1,
    setTabActiveStep1: () => { },

    customer: { ...initCustomerPaymentContext },
    setCustomer: () => { },

    rentCustomer: { ...initCustomerPaymentContext },
    setRentCustomer: () => { },

    sellCustomer: { ...initCustomerPaymentContext },
    setSellCustomer: () => { },


    discountFee: 0,
    setDiscountFee: () => { },

    rentDepositFee: 0,
    setRentDepositFee: () => { },

    sellDepositFee: 0,
    setSellDepositFee: () => { },

    rentSurchargeFee: 0,
    setRentSurchargeFee: () => { },

    sellSurchargeFee: 0,
    setSellSurchargeFee: () => { },

    sellTotal: 0,
    setSellTotal: () => { },

    rentTotal: 0,
    setRentTotal: () => { },

    rentTransportFee: 0,
    setRentTransportFee: () => { },

    sellTransportFee: 0,
    setSellTransportFee: () => { },

    rentCustomerApply: false,
    setRentCustomerApply: () => { },

    sellCustomerApply: false,
    setSellCustomerApply: () => { },

    rentCustomerError: {},
    setRentCustomerError: () => { },

    sellCustomerError: {},
    setSellCustomerError: () => { },

    modalCustomer: {
        open: false,
        type: null
    },
    setModalCustomer: () => { },

    orderSuccess: {
        rent_order_id: null,
        purchase_order_id: null
    },
    setOrderSuccess: () => { },

    spinLoading: false,
    setSpinLoading: () => { },

    handleCalculateFee: () => { },

    isMobile: false,
    setIsMobile: () => { },
}

const PaymentContext = React.createContext<IPaymentContext>({ ...initialValuePaymentContext });

export const PaymentProvider = ({ children }: { children: JSX.Element | any }) => {
    const iAppContext = useContext(AppContext);

    const [sellCarts, setSellCarts] = useState<IProductDetailPayment[] | any>([])
    const [rentCarts, setRentCarts] = useState<IProductDetailPayment[] | any>([])
    const [currentStep, setCurrentStep] = useState(1)
    const [rentAdditionalInformation, setRentAdditionalInformation] = useState<IAdditionalInformation>({ ...initAdditionalInformation })
    const [sellAdditionalInformation, setSellAdditionalInformation] = useState<IAdditionalInformation>({ ...initAdditionalInformation })
    const [tabActiveStep1, setTabActiveStep1]: any = useState(1)
    const [customer, setCustomer] = useState<ICustomerAppContext>({ ...initCustomerPaymentContext })
    const [rentCustomer, setRentCustomer] = useState<ICustomerAppContext>({ ...initCustomerPaymentContext })
    const [sellCustomer, setSellCustomer] = useState<ICustomerAppContext>({ ...initCustomerPaymentContext })

    const [discountFee, setDiscountFee] = useState<number>(0)
    const [rentDepositFee, setRentDepositFee] = useState<number>(0)
    const [sellDepositFee, setSellDepositFee] = useState<number>(0)
    const [rentSurchargeFee, setRentSurchargeFee] = useState<number>(0)
    const [sellSurchargeFee, setSellSurchargeFee] = useState<number>(0)
    const [sellTotal, setSellTotal] = useState<number>(0)
    const [rentTotal, setRentTotal] = useState<number>(0)
    const [rentTransportFee, setRentTransportFee] = useState<number>(0)
    const [sellTransportFee, setSellTransportFee] = useState<number>(0)

    /** Not add to local storage */
    const [loaded, setLoaded] = useState(false);
    const [rentCustomerApply, setRentCustomerApply] = useState(false);
    const [sellCustomerApply, setSellCustomerApply] = useState(false);
    const [rentCustomerError, setRentCustomerError] = useState<any>({})
    const [sellCustomerError, setSellCustomerError] = useState<any>({})
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState(false);
    const [modalCustomer, setModalCustomer] = useState<any>({
        open: false,
        type: null,
    })

    const [orderSuccess, setOrderSuccess] = useState({
        rent_order_id: null,
        purchase_order_id: null,
    })

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth < 768);
    }

    function onStorageUpdate(e: any) {
        const { key, newValue } = e;

        let payment: IPaymentContext | any = JSON.parse(newValue);
        if (key === "payment") {
            if (sellCarts.length !== payment.sellCarts.length) {
                setSellCarts(payment.sellCarts ?? []);
            }
            if (rentCarts.length !== payment.rentCarts.length) {
                setRentCarts(payment.rentCarts ?? []);
            }

        }
    }

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
        // window.addEventListener("storage", onStorageUpdate);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            //window.removeEventListener("storage", onStorageUpdate);
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        }
    }, [rentCarts, sellCarts])
    //console.log("sellCarts", sellCarts, rentCarts);

    useEffect(() => {
        try {
            setTabActiveStep1(localStorage.getItem("tabActiveStep1") ?? 1)
            let strPayment: string | null = browserStored.getLocalStored(STORAGE_KEYS.PAYMENT);
            if (strPayment) {
                let payment: IPaymentContext | any = JSON.parse(strPayment);
                setSellCarts(payment.sellCarts ?? []);
                setRentCarts(payment.rentCarts ?? []);
                // setCurrentStep(payment.currentStep ?? 1);

                if (payment.hasOwnProperty('rentAdditionalInformation')) {
                    setRentAdditionalInformation(payment.rentAdditionalInformation);
                }

                if (payment.hasOwnProperty('sellAdditionalInformation')) {
                    setSellAdditionalInformation(payment.sellAdditionalInformation);
                }

                if (payment.hasOwnProperty('customer')) {
                    setCustomer(payment.customer);
                }

                if (payment.hasOwnProperty('rentCustomer')) {
                    setRentCustomer(payment.rentCustomer);
                }

                if (payment.hasOwnProperty('sellCustomer')) {
                    setSellCustomer({
                        ...payment.sellCustomer,
                        sex: payment?.sellCustomer?.sex || 0,
                    });
                }
                if (!localStorage.getItem("tabActiveStep1")) {
                    if (payment?.sellCarts?.length === 0 && payment?.rentCarts?.length > 0) {
                        setTabActiveStep1(1);
                    } else if (payment?.sellCarts?.length > 0 && payment?.rentCarts?.length === 0) {
                        setTabActiveStep1(2);
                    } else {
                        setTabActiveStep1(payment.tabActiveStep1 ?? 1);
                    }
                }

                setDiscountFee(payment.discountFee ?? 0);
                setRentDepositFee(payment.rentDepositFee ?? 0);
                setSellDepositFee(payment.sellDepositFee ?? 0);
                setRentSurchargeFee(payment.rentSurchargeFee ?? 0);
                setSellSurchargeFee(payment.setSellSurchargeFee ?? 0);
                setRentTotal(payment.rentTotal ?? 0);
                setSellTotal(payment.sellTotal ?? 0);
                setRentTransportFee(payment.rentTransportFee ?? 0);
                setSellTransportFee(payment.sellTransportFee ?? 0);
            }
        } catch (err) { }

        setLoaded(true)
    }, [])

    useEffect(() => {
        setSellTotal(totalPayment(sellCarts, false).totalSell);

        if (sellCarts.length === 0) {
            setSellTransportFee(0);
        }
    }, [sellCarts])

    useEffect(() => {
        if (sellCarts.length === 0) {
            setSellTransportFee(0);
        }
        if (rentCarts.length === 0) {
            setRentTransportFee(0);
        }
    }, [])
    //console.log("rentSurchargeFee", rentSurchargeFee);

    useEffect(() => {

        let cloneRentCart = checkHasBundling(rentCarts);
        //console.log("checkHasBundling", cloneRentCart);

        let total = totalPayment(cloneRentCart, false);
        let paymentRentPrice = totalPaymentRentPrice(cloneRentCart);
        //console.log("total.totalRent", total);
        setRentTotal(total.totalRent)
        let rentSurchargeFee = calculateSurcharge(iAppContext.workingDay, iAppContext.holidays, cloneRentCart, iAppContext.generalSetting, false);
        setRentSurchargeFee(rentSurchargeFee < 0 ? 0 : rentSurchargeFee);
        //console.log("surcharge", calculateSurcharge(rentCarts, iAppContext.generalSetting, false));
        //console.log("paymentRentPrice", paymentRentPrice, total.totalRent);
        let totalRentDeposit = totalPayment(cloneRentCart, true);
        //console.log("deposit", paymentRentPrice - total.totalRent < 0 ? 0 : paymentRentPrice - totalRentDeposit.totalRent);
        //console.log("paymentRentPrice", paymentRentPrice);
        //console.log("totalRentDeposit", totalRentDeposit);
        setRentDepositFee(
            paymentRentPrice - total.totalRent < 0 ? 0 : paymentRentPrice - totalRentDeposit.totalRent
        )
    }, [rentCarts, iAppContext.generalSetting])

    useEffect(() => {
        if (loaded) {
            let object = {
                sellCarts: sellCarts,
                rentCarts: rentCarts,
                currentStep: currentStep,
                rentAdditionalInformation: rentAdditionalInformation,
                sellAdditionalInformation: sellAdditionalInformation,
                tabActiveStep1: tabActiveStep1,
                customer: customer,
                rentCustomer: rentCustomer,
                sellCustomer: sellCustomer,

                discountFee: discountFee,
                rentDepositFee: rentDepositFee,
                sellDepositFee: sellDepositFee,
                rentSurchargeFee: rentSurchargeFee,
                sellTotal: sellTotal,
                rentTotal: rentTotal,
                rentTransportFee: rentTransportFee,
                sellTransportFee: sellTransportFee,
                sellSurchargeFee: sellSurchargeFee,
            }

            browserStored.setLocalStored(STORAGE_KEYS.PAYMENT, object);

        }
    }, [
        sellCarts,
        rentCarts,
        currentStep,
        rentAdditionalInformation,
        sellAdditionalInformation,
        tabActiveStep1,
        customer,
        rentCustomer,
        sellCustomer,

        discountFee,
        rentDepositFee,
        sellDepositFee,
        rentSurchargeFee,
        sellTotal,
        rentTotal,
        rentTransportFee,
        sellTransportFee,
        sellSurchargeFee
    ])

    const handleCalculateFee = async (
        carts: IProductDetailPayment[],
        isSell: boolean,
        customerData: ICustomerAppContext,
        additionalInformation: IAdditionalInformation
    ) => {
        //console.log("checkHasData", checkHasData(carts));
        if (
            additionalInformation.delivery_type === PaymentConst.DeliveryType.cod
            && checkHasData(carts)
            && customerData?.province_name
            && customerData?.district_name
            && customerData?.address
        ) {
            //console.log("Innn 12323 handleCalculateFee");

            await setSpinLoading(true)
            const weight = prepareCalculateTransportFee(carts);

            const body = {
                province: customerData?.province_name,
                district: customerData?.district_name,
                address: customerData?.address,
                value: 0,
                weight: weight,
                warehouse_id: null
            }

            let response: any = await getFee(body);

            if (response?.fee?.fee) {
                if (isSell) {
                    setSellTransportFee(response?.fee?.fee ?? 0)
                } else {
                    setRentTransportFee(response?.fee?.fee ?? 0)
                }
            }

            await setSpinLoading(false);

        } else {
            if (isSell) {
                setSellTransportFee(0)
            } else {
                setRentTransportFee(0)
            }
        }
    }

    return (
        <PaymentContext.Provider value={{
            sellCarts, setSellCarts,
            rentCarts, setRentCarts,
            currentStep, setCurrentStep,
            sellCustomer: sellCustomer,
            setSellCustomer: setSellCustomer,
            rentCustomer: sellCustomer,
            setRentCustomer: setSellCustomer,
            rentAdditionalInformation, setRentAdditionalInformation,
            sellAdditionalInformation, setSellAdditionalInformation,
            customer, setCustomer,
            tabActiveStep1, setTabActiveStep1,
            discountFee, setDiscountFee,
            rentDepositFee, setRentDepositFee,
            sellDepositFee, setSellDepositFee,
            rentSurchargeFee, setRentSurchargeFee,
            sellTotal, setSellTotal,
            rentTotal, setRentTotal,
            rentTransportFee, setRentTransportFee,
            sellTransportFee, setSellTransportFee,
            sellSurchargeFee, setSellSurchargeFee,

            sellCustomerApply, setSellCustomerApply,
            rentCustomerApply, setRentCustomerApply,
            rentCustomerError, setRentCustomerError,
            sellCustomerError, setSellCustomerError,

            modalCustomer, setModalCustomer,
            orderSuccess, setOrderSuccess,

            spinLoading, setSpinLoading,

            handleCalculateFee,

            isMobile, setIsMobile,
            loaded,
        }}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentContext;
